document.querySelectorAll('a[href^="#"]').forEach(anchor => {
	anchor.addEventListener('click', function (e) {
		e.preventDefault()

		const targetId = this.getAttribute('href')
		const targetElement = document.querySelector(targetId)

		if (targetElement) {
			const offset = 40
			const elementPosition = targetElement.getBoundingClientRect().top
			const offsetPosition = elementPosition - offset

			window.scrollBy({
				top: offsetPosition,
				behavior: 'smooth'
			})
		} else {
			window.location.href = `/${targetId}`
		}
	})
})
