import AOS from 'aos'
import { CountUp } from 'countup.js'

AOS.init()

const numbers = document.querySelectorAll('.num')

numbers.forEach(number => {
	const text = number.textContent
		.split('')
		.filter(char => char !== ',')
		.join('')

	const options = {
		separator: ',',
		duration: 4,
		enableScrollSpy: true
	}

	const countUp = new CountUp(number, text || 0, options)
})

console.log('animations.js loaded')
