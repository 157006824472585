document.addEventListener('DOMContentLoaded', () => {
	const dropDowns = document.querySelectorAll('.dropdown')

	function toggleMenu(menuButton, menu, arrow) {
		const isExpanded = menuButton.getAttribute('aria-expanded') === 'true'
		menuButton.setAttribute('aria-expanded', !isExpanded)
		menu.classList.toggle('hidden', isExpanded)
		arrow.classList.toggle('rotate-180')
	}

	dropDowns.forEach(dropDown => {
		const menuButton = dropDown.querySelector('.menu-button')
		const menu = dropDown.querySelector('.menu')

		const arrow = dropDown.querySelector('.menu-icon')

		menuButton.addEventListener('click', () => {
			toggleMenu(menuButton, menu, arrow)
		})

		document.addEventListener('click', event => {
			if (!dropDown.contains(event.target)) {
				menuButton.setAttribute('aria-expanded', 'false')
				menu.classList.add('hidden')
				arrow.classList.remove('rotate-180')
			}
		})

		document.addEventListener('keydown', event => {
			if (event.key === 'Escape') {
				menuButton.setAttribute('aria-expanded', 'false')
				menu.classList.add('hidden')
				arrow.classList.remove('rotate-180')
			}
		})
	})
})
