document.addEventListener('DOMContentLoaded', () => {
	// Открытие модального окна
	document.querySelectorAll('[data-modal-open]').forEach(button => {
		button.addEventListener('click', () => {
			const modalId = button.getAttribute('data-modal-open')
			const modal = document.querySelector(`[data-modal="${modalId}"]`)
			modal.classList.remove('hidden')
			document.body.style.overflow = 'hidden'
		})
	})

	// Закрытие модального окна по кнопке или по клику на оверлей
	document.querySelectorAll('[data-modal]').forEach(modal => {
		const closeButton = modal.querySelector('[data-modal-close]')
		const modalBody = modal.querySelector('[data-modal-body]')

		closeButton.addEventListener('click', () => {
			modal.classList.add('hidden')
			const closeEvent = new CustomEvent('modalClosed', { detail: { modalId: modal.getAttribute('data-modal') } })
			document.dispatchEvent(closeEvent)
			document.body.style.overflow = 'auto'
		})

		modal.addEventListener('click', event => {
			if (event.target !== modalBody && !modalBody.contains(event.target)) {
				modal.classList.add('hidden')
				document.body.style.overflow = 'auto'
				const closeEvent = new CustomEvent('modalClosed', {
					detail: { modalId: modal.getAttribute('data-modal') }
				})
				document.dispatchEvent(closeEvent)
			}
		})
	})
})
