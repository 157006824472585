import Swiper from 'swiper'
import 'swiper/css'
import { Autoplay } from 'swiper/modules'

const cryptoSlider = new Swiper('.crypto-slider', {
	slidesPerView: 2,
	loop: true,
	spaceBetween: 40,
	freeMode: true,
	fadeEffect: {
		crossFade: true
	},
	autoplay: {
		delay: 0,
		disableOnInteraction: false
	},
	speed: 3000,
	effect: 'slide',
	modules: [Autoplay],
	breakpoints: {
		320: {
			slidesPerView: 2,
			spaceBetween: 20
		},
		480: {
			slidesPerView: 3,
			spaceBetween: 30
		},
		640: {
			slidesPerView: 4,
			spaceBetween: 40
		},
		768: {
			slidesPerView: 5,
			spaceBetween: 50
		},
		1024: {
			slidesPerView: 6,
			spaceBetween: 60
		},
		1280: {
			slidesPerView: 7,
			spaceBetween: 70
		},
		1440: {
			slidesPerView: 8,
			spaceBetween: 80
		},
		1600: {
			slidesPerView: 9,
			spaceBetween: 90
		},
		1920: {
			slidesPerView: 10,
			spaceBetween: 100
		}
	}
})
