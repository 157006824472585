import { formValidation } from '../../base/js/formValidation'
import PublicGoogleSheetsParser from 'public-google-sheets-parser'

const parser = new PublicGoogleSheetsParser('1Q6cwtqOdAIb92x5z4RydQdr0l4x46WRZGfblv-PclAM')
const userName = document.querySelector('.username')
const userNickname = document.querySelector('.usernick')
const userLink = document.querySelector('.userlink')
const notFound = document.querySelector('.result-bad')
const found = document.querySelector('.result-good')
let parsedData = []

parser.parse().then(data => {
	parsedData = data
})

const validateUserForm = document.getElementById('validate-user-form')

if (validateUserForm) {
	const validation = formValidation(validateUserForm)

	validation.addControl('#username', [
		{
			rule: 'required'
		},
		{
			rule: 'minLength',
			value: 3
		}
	])

	validation.onSuccess(async event => {
		event.preventDefault() // Переносим preventDefault в начало, чтобы избежать возможных проблем

		const formData = new FormData(event.target)
		const usernameInput = formData.get('username').toLowerCase() // Приводим ввод пользователя к нижнему регистру

		const hasUser = parsedData.find(user => {
			return user.name.toLowerCase().includes(usernameInput) // Проверяем наличие части имени
		})

		if (hasUser !== undefined) {
			userName.textContent = hasUser.name

			if (hasUser.nick) {
				const formattedNick = hasUser.nick.replace('@', '')

				userNickname.textContent = hasUser.nick
				userNickname.href = `https://t.me/${formattedNick}`
			}

			if (hasUser.links) {
				userLink.href = hasUser.links
				userLink.textContent = hasUser.links
				userLink.parentElement.classList.remove('hidden')
			} else {
				userLink.parentElement.classList.add('hidden')
			}

			found.classList.remove('hidden')
			notFound.classList.add('hidden')
		} else {
			found.classList.add('hidden')
			notFound.classList.remove('hidden')
		}
	})
}

document.addEventListener('modalClosed', event => {
	const { modalId } = event.detail

	if (modalId === 'validate-user-modal') {
		validateUserForm.reset()
		found.classList.add('hidden')
		notFound.classList.add('hidden')
	}
})
