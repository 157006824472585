document.addEventListener('DOMContentLoaded', () => {
	const tabs = document.querySelectorAll('.tabs li')
	const contents = document.querySelectorAll('.tab-content')

	tabs.forEach(tab => {
		tab.addEventListener('click', () => {
			const button = tab.querySelector('button')
			const target = button.getAttribute('data-tab')

			// Скрыть весь контент и убрать класс active у всех табов
			contents.forEach(content => {
				content.classList.add('hidden')
			})

			tabs.forEach(tab => {
				tab.classList.remove('active')
			})

			// Показать контент для выбранного таба и добавить класс active
			document.querySelector(`.tab-content[data-tab-content="${target}"]`).classList.remove('hidden')
			tab.classList.add('active')
		})
	})
})
