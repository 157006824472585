const openMenuBtn = document.getElementById('openMenu')
const closeMenuBtn = document.getElementById('closeMenu')
const menu = document.getElementById('menu')

const body = document.querySelector('body')

if (openMenuBtn) {
	openMenuBtn.addEventListener('click', () => {
		menu.classList.add('!opacity-100', '!translate-x-0')
		body.style.overflow = 'hidden'
		body.style.height = '100vh'
	})

	closeMenuBtn.addEventListener('click', () => {
		menu.classList.remove('!opacity-100', '!translate-x-0')
		body.style.overflow = 'auto'
		body.style.height = 'auto'
	})

	menu.querySelectorAll('a').forEach(link => {
		link.addEventListener('click', () => {
			menu.classList.remove('!opacity-100', '!translate-x-0')
			body.style.overflow = 'auto'
			body.style.height = 'auto'
		})
	})

	window.addEventListener('resize', () => {
		if (window.innerWidth > 766) {
			menu.classList.remove('!opacity-100', '!translate-x-0')
			body.style.overflow = 'auto'
			body.style.height = 'auto'
		}
	})
}
