// Ваш JavaScript код

import Notify from 'simple-notify'
import 'simple-notify/dist/simple-notify.css'
import { formValidation } from '../../base/js/formValidation'

function showNotification(status, text) {
	// eslint-disable-next-line no-new
	new Notify({
		status,
		title: status === 'success' ? 'Success!' : 'Error!',
		text,
		effect: 'fade',
		speed: 300,
		showIcon: true,
		showCloseButton: true,
		autoclose: true,
		autotimeout: 3000,
		type: 'outline',
		position: 'right top'
	})
}

const contactForm = document.getElementById('contact-form')

if (contactForm) {
	const validation = formValidation(contactForm)

	validation.addControl('#email', [
		{
			rule: 'required'
		}
	])
	validation.addControl('#text', [
		{
			rule: 'required'
		}
	])

	const fileInput = document.getElementById('file')
	const fileList = document.getElementById('file-view')

	if (fileInput) {
		fileInput.addEventListener('change', event => {
			const files = event.target.files

			fileList.innerHTML = ''
			for (let i = 0; i < files.length; i++) {
				const li = document.createElement('li')
				li.textContent = files[i].name
				fileList.appendChild(li)
				// Добавляем кнопку удаления
				const removeButton = document.createElement('button')
				removeButton.textContent = 'X'
				removeButton.type = 'button'
				removeButton.classList.add('ml-2', 'text-red-500')
				removeButton.addEventListener('click', () => {
					fileInput.value = ''
					fileList.innerHTML = ''
				})
				li.appendChild(removeButton)
			}
		})
	}

	validation.onSuccess(async event => {
		const data = new FormData(event.target)

		console.log(event.target)

		event.preventDefault() // отключаем перезагрузку/перенаправление страницы
		try {
			const response = await fetch(event.target.action, {
				method: 'POST',
				body: data
			})

			console.log(response)
			console.log(data)

			if (!response.ok) {
				showNotification('error', 'An error occurred while sending the message!')
			} else {
				showNotification('success', 'The message was successfully sent!')
				contactForm.reset()
				fileList.innerHTML = ''
			}
		} catch (error) {
			// eslint-disable-next-line no-alert
			alert(error)
		}
	})
}
