import Swiper from 'swiper'
import 'swiper/css'
import { Autoplay } from 'swiper/modules'

const partnersSlider = new Swiper('.partners-slider', {
	slidesPerView: 'auto',
	loop: true,
	spaceBetween: 30,
	freeMode: true,
	fadeEffect: {
		crossFade: true
	},
	autoplay: {
		delay: 0,
		pauseOnMouseEnter: true
	},

	speed: 4000,
	effect: 'slide',
	modules: [Autoplay]
})

const teamSlider = new Swiper('.team-slider', {
	slidesPerView: 'auto',
	loop: true,
	spaceBetween: 30,
	freeMode: true,
	fadeEffect: {
		crossFade: true
	},
	autoplay: {
		delay: 0,
		pauseOnMouseEnter: true,
		reverseDirection: true
	},

	speed: 4000,
	effect: 'slide',
	modules: [Autoplay]
})
